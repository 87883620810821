.header {
    display: flex;
    align-items: center;
    padding: 1em;
    background-color: rgb(13, 23, 57); /* Dark blue background */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .header-logo {
    height: 50px; /* Adjust logo height as needed */
    width: auto;
    margin-left: 1em;
  }
  