.status-page {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 2em;
    color: #333; /* Text color for better readability */
  }
  
  .status-summary {
    padding: 1em;
    margin-bottom: 1.5em;
    border-radius: 8px;
    font-size: 1.2em;
  }
  
  .service-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5em;
  }
  
  .service {
    padding: 16px;
    border-radius: 8px;
    width: 180px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    transition: transform 0.2s ease;
  }
  
  .service:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .service h2 {
    margin-bottom: 8px;
    font-size: 1.1em;
  }
  
  .service.operational {
    background-color: #d4edda;
    color: #155724; /* Green for "Operational" */
  }
  
  .service.degraded-performance {
    background-color: #fff3cd;
    color: #856404; /* Yellow for "Degraded Performance" */
  }
  
  .service.partial-outage {
    background-color: #f8d7da;
    color: #721c24; /* Light red for "Partial Outage" */
  }
  
  .service.not-working {
    background-color: #f5c6cb;
    color: #721c24; /* Red for "Not Working" */
  }  